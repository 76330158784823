<template>
    <div class="page">
        <div class="login-background-wrapper">
            <div class="login-background"></div>
        </div>
        <div class="login-wrapper">
            <div class="header">
                <img v-svg-inline src="./assets/onboard_logo.svg" />
                <br />
                <h1 class="bold">The journey begins here</h1>
                <br /><br />
                <div class="input">
                    <input
                        id="client"
                        ref="client"
                        v-model.lazy="code"
                        autocapitalize="off"
                        placeholder="Client Code"
                        type="text"
                        @keyup.enter.prevent="submitClientCode"
                    />
                    <button @click="submitClientCode">Go</button>
                </div>
            </div>
            <div class="form">
                <div class="whiteform">
                    <div class="container">
                        <h3 class="title bold center">Download</h3>
                        <br />
                        <h4 v-if="!visibleReleases.length">No available Releases</h4>
                        <div v-if="visibleReleases.length" class="download-info">
                            <h4>
                                Version <b>{{ latest.version }}</b> - {{ formatDate(latest.date) }}
                            </h4>
                            <br />
                            <h5
                                class="bold underline"
                                @click="toggleMinimumRequirements"
                                v-if="!showingMinRequirements"
                            >
                                View Minimum Requirements
                            </h5>
                            <div v-if="showingMinRequirements">
                                <h5 class="bold underline" @click="toggleMinimumRequirements">
                                    Hide Minimum Requirements
                                </h5>
                                <br />
                                <h5 v-if="isOnAndroid">{{ info.metadata.android.info }}</h5>
                                <h5 v-if="isOniOS">{{ info.metadata.ios.info }}</h5>
                            </div>
                            <br />
                            <div v-if="latest.notes?.length">
                                <h3 class="bold">Release Notes:</h3>
                                <ul>
                                    <li v-for="(i, j) in latest.notes" :key="j">{{ i }}</li>
                                </ul>
                                <br />
                            </div>

                            <div class="flex download-versioning">
                                <div class="flex">
                                    <a
                                        v-if="isOnAndroid"
                                        :href="latest.android"
                                        download
                                        class="download android"
                                    >
                                        <span class="bold">Android</span>
                                        <img v-svg-inline src="./assets/download_button.svg" />
                                    </a>
                                    <a
                                        v-if="isOniOS"
                                        :href="latest.ios"
                                        download
                                        class="download ios"
                                    >
                                        <span class="bold">iOS</span>
                                        <img v-svg-inline src="./assets/download_button.svg" />
                                    </a>
                                </div>
                                <h5
                                    @click="toggleOlderVersions"
                                    v-if="!showingOlderVersions && visibleReleases.length > 1"
                                    class="bold underline"
                                >
                                    View older versions
                                </h5>
                                <h5
                                    @click="toggleOlderVersions"
                                    v-if="showingOlderVersions"
                                    class="bold underline"
                                >
                                    Hide older versions
                                </h5>
                            </div>
                            <div v-if="showingOlderVersions">
                                <br /><br />
                                <h3 class="title bold center">Older Versions</h3>

                                <div
                                    class="flex older-version"
                                    v-for="release in allButLatest"
                                    :key="release.version"
                                >
                                    <h4>
                                        <b>{{ release.version }}</b> -
                                        {{ formatDate(release.date) }}
                                    </h4>

                                    <a
                                        v-if="isOnAndroid"
                                        :href="release.android"
                                        download
                                        class="download minimal android"
                                    >
                                        <span class="bold">Android</span>
                                    </a>
                                    <a
                                        v-if="isOniOS"
                                        :href="release.ios"
                                        download
                                        class="download minimal ios"
                                    >
                                        <span class="bold">iOS</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright-footer">
                <div class="left-footer">
                    <a href="https://mademedia.tech">
                        <img v-svg-inline src="./assets/made_media_logo.svg" />
                        <span class="long-copyright">Designed and developed by Made Media</span>
                        <span class="short-copyright">Made Media</span>
                    </a>
                </div>
                <div class="right-footer">
                    <a class="contact-link" href="mailto:info@mademedia.tech"
                        >Questions? Contact us now</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            info: {
                releases: [], //releases are sorted by version number, not date
            },
            code: '',
            showingMinRequirements: false,
            showingOlderVersions: false,
            isOnAndroid: true,
            isOniOS: true,
        };
    },
    computed: {
        visibleReleases() {
            return (this.info.releases ?? []).filter((c) => {
                if (!c.clients || !c.clients.length || c.clients[0] == 'external') {
                    return true;
                }
                if (this.code == 'internal') {
                    return true;
                }

                return c.clients.indexOf(this.code) > -1;
            });
        },
        latest() {
            return this.visibleReleases[0] ?? {};
        },
        allButLatest() {
            return this.visibleReleases.slice(1);
        },
    },
    methods: {
        submitClientCode() {
            location.hash = '#' + this.$refs.client.value;
            document.activeElement.blur();
            this.showingOlderVersions = false;
        },
        toggleMinimumRequirements() {
            this.showingMinRequirements = !this.showingMinRequirements;
        },
        toggleOlderVersions() {
            this.showingOlderVersions = !this.showingOlderVersions;
        },
        haveReleases(result) {
            if (typeof result == 'string') {
                try {
                    result = JSON.parse(result);
                    result.releases = result.releases.sort((a, b) => {
                        a._versionNumber = a.version.split('.').map((c) => {
                            return parseInt(c);
                        });
                        b._versionNumber = b.version.split('.').map((c) => {
                            return parseInt(c);
                        });

                        if (a._versionNumber[0] > b._versionNumber[0]) {
                            //major is old
                            return -1;
                        } else if (b._versionNumber[0] > a._versionNumber[0]) {
                            return 1; //current is newer than req
                        }

                        if (a._versionNumber[1] > b._versionNumber[1]) {
                            //minor is old
                            return -1;
                        } else if (b._versionNumber[1] > a._versionNumber[1]) {
                            return 1; //current is newer than req
                        }

                        if (a._versionNumber[2] > b._versionNumber[2]) {
                            return -1;
                        } else if (a._versionNumber[2] < b._versionNumber[2]) {
                            return 1;
                        }
                        return 0;
                    });
                    this.info = result;
                } catch (e) {
                    //
                }
            } else if (typeof result == 'object') {
                this.info = result;
            }
        },
        formatDate(date) {
            const d = new Date(date);
            let options = {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
            };

            return d.toLocaleDateString(undefined, options);
        },
        detectPlatform() {
            const ua = navigator.userAgent;

            let isOniOS = true;
            let isOnAndroid = true;
            if (!ua.match(/(iPad|iPhone|iPod)/i)) {
                isOniOS = false;
            }

            if (!ua.match(/(android)/i)) {
                isOnAndroid = false;
            }

            if (!isOniOS && !isOnAndroid) {
                return;
            }

            this.isOnAndroid = isOnAndroid;
            this.isOniOS = isOniOS;
        },
    },
    async mounted() {
        const hash = location.hash;
        if (hash) {
            this.code = hash.replace('#', '');
        }

        this.detectPlatform();

        const xhr = new XMLHttpRequest();

        xhr.onreadystatechange = () => {
            if (xhr.readyState == 4 && xhr.status == 200) {
                this.haveReleases(xhr.responseText);
            }
        };

        xhr.open('get', 'releases.json', true);
        xhr.send();
    },
};
</script>

<style lang="scss">
*:focus {
    outline: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: normal;
}

#app {
    --white: #ffffff;
    --dark-grey: #212b36;
    --med-grey: #919eab;
    --light-grey: #86919b;
    --accent: #4a83f1;
    --ios-color: #69b5f2;
    --android-color: #6fc992;

    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background-color: var(--dark-grey);
    background: linear-gradient(345deg, rgba(33, 43, 54, 0.81) 20%, var(--dark-grey) 65%);

    .center {
        text-align: center;
    }

    .bold {
        font-weight: bold;
    }

    .underline {
        text-decoration: underline;
        cursor: pointer;
        user-select: none;
    }

    .page {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
    }

    .login-background-wrapper {
        width: 100%;
        height: 100%;
        z-index: 0;
        position: fixed;
        overflow: hidden;

        .login-background {
            position: absolute;
            z-index: 0;
            width: calc(100vw + 100px);
            left: -50px;
            height: 100vh;
            background: url('./assets/login-background.png');
            mix-blend-mode: normal;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            transform: rotate(-22deg);

            @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                background: url('./assets/login-background_2x.png');
            }

            @media (max-width: 700px) {
                background-size: 150vw;
            }

            @media (max-width: 500px) {
                background-size: 200vw;
            }
        }
    }

    .login-wrapper {
        z-index: 1;
        position: relative;
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 35px 1.5fr 1fr 1fr 85px;
        grid-template-areas:
            'locale'
            'header'
            'form'
            '.'
            'footer';
    }

    .header {
        grid-area: header;
        color: var(--white);
        display: flex;
        align-items: center;
        flex-direction: column;
        align-self: center;
        margin: 50px 0 25px;

        svg {
            max-width: 80vw;
        }

        h1 {
            font-size: 16px;
            line-height: 32px;
        }
    }

    .input {
        display: grid;
        grid-template-columns: auto 50px;
        width: calc(100% - 20px);
        max-width: 250px;
        height: 52px;
        background-color: var(--white);
        border-radius: 5px;
        padding: 0 8px 0 0;
        gap: 10px;
        box-sizing: border-box;
        align-items: center;
        border: 1px solid var(--white);

        input {
            width: 100%;
            padding-left: 14px;
            height: 52px;
            box-sizing: border-box;
            border: none;
            background-color: transparent;
            color: var(--dark-grey);
            font-size: 14px;
        }

        button {
            -webkit-appearance: none;
            color: var(--white);
            background-color: var(--accent);
            border: none;
            border-radius: 4px;
            padding: 10px;
            cursor: pointer;
        }
    }

    .copyright-footer {
        grid-area: footer;
        display: grid;
        grid-template-columns: auto 1fr;
        color: var(--dark-grey);
        padding: 32px 50px;
        box-sizing: border-box;
        position: sticky;
        top: 100vh;
        width: 100vw;

        @media (max-width: 600px) {
            padding: 20px;
        }

        .short-copyright {
            display: none;
        }

        @media (max-width: 600px) {
            .long-copyright {
                display: none;
            }
            .short-copyright {
                display: block;
            }
        }

        .left-footer,
        .right-footer,
        .left-footer a {
            display: flex;
            align-items: center;
        }

        .left-footer,
        .left-footer a {
            justify-content: flex-start;
            font-size: 11px;
            line-height: 15px;
            color: var(--med-grey);
            text-decoration: none;

            svg {
                color: var(--light-grey);
                margin-right: 9px;
            }
            span.dash {
                margin: 0 5px;
            }
        }

        .right-footer {
            justify-content: flex-end;

            .contact-link {
                text-decoration: none;
                color: var(--white);
                font-size: 12px;
                padding-bottom: 2px;
                margin-top: 3px;
                border-bottom: 1px solid var(--white);
                font-weight: bold;
            }
        }

        &.dark {
            .right-footer {
                .contact-link {
                    color: var(--med-grey);
                    border-bottom: 1px solid var(--med-grey);
                }
            }
        }
    }

    .form {
        grid-area: form;
        justify-self: center;

        .whiteform {
            box-shadow: 0 4px 50px rgba(69, 79, 91, 0.12);
            border-radius: 7px;
            width: min(585px, 90vw);
            background-color: var(--white);
            color: var(--dark-grey);
            box-sizing: border-box;
            text-align: initial;

            > .container {
                padding: 25px;
            }

            h3 {
                text-transform: uppercase;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.04em;
                margin-bottom: 12px;
            }

            p {
                font-size: 14px;
                line-height: 18px;
                color: var(--light-grey);
            }

            @media (max-width: 500px) {
                width: 100vw;
                border-radius: 0;
            }
        }
    }

    .flex {
        display: flex;
        align-items: center;

        &.download-versioning {
            justify-content: space-between;
            align-items: flex-end;
        }

        &.older-version > * + * {
            margin-left: 10px;
        }

        &.older-version + .older-version {
            margin-top: 10px;
        }

        > * + * {
            margin-left: 25px;
        }
    }

    .download {
        display: grid;
        grid-template-columns: auto 30px;
        align-items: center;
        gap: 10px;
        padding: 10px;
        background: var(--dark-grey);
        color: var(--white);
        text-decoration: none;
        border-radius: 4px;
        box-shadow: 0 4px 50px rgba(69, 79, 91, 0.25);

        span {
            text-decoration: none;
        }

        svg {
            width: 30px;
            height: 30px;
        }

        &.android {
            background: var(--android-color);
        }

        &.ios {
            background: var(--ios-color);
        }

        &.minimal {
            padding: 5px;
            grid-template-columns: 1fr;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}
</style>
